import React from "react";
import { createRoot } from 'react-dom/client';
import { hydrateRoot } from "react-dom";
import Router from "./Router";
import { Amplify} from "@aws-amplify/core";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./screen/components/ScrollToTop";
import awsconfig from "./aws-exports";
import ReactGA from "react-ga";
import {clarity} from 'react-microsoft-clarity';
const TRACKING_ID = "G-799QZPYY77"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

import "lazysizes";
// import a plugin
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import AOS from "aos";
import "aos/dist/aos.css";
import "./font.scss";
import MetaTags from "react-helmet";
import "@aws-amplify/ui-react/styles.css";

Amplify.configure({ ...awsconfig, ssr: true });

clarity.init("p22zwaqhp5");

window.prerenderReady = false; 

const rootElement = createRoot(document.getElementById("root"));
rootElement.render(
  <React.StrictMode>
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Router />
      </BrowserRouter>
    </div>
  </React.StrictMode>
);

AOS.init({
  offset: 0,
  duration: 1000,
});
